
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { namespace } from 'vuex-class'
import { Vue, Component, Prop } from 'vue-property-decorator'
import * as sq from '~/store/modules/sq'
// import * as cart from '~/store/modules/cart'
import { Carrier } from '~/store/interfaces'
import { mergeUrl } from '~/store/api'

const SQ = namespace(sq.name)
// const Cart = namespace(cart.name)

@Component({
  components: {
    AddressSearchLong: () => import('~/components/AddressSearchLong.vue'),
    AdvancedSearch: () => import('~/components/AdvancedSearch.vue'),
    LoadingSpinner: () => import('~/components/Icons/LoadingSpinner.vue'),
    RedirectionLoading: () => import('~/components/LandingPage/RedirectionLoading.vue')
  },
  data() {
    return {
      siteId: process.env.siteId,
      brand: process.env.brand
    }
  },
  props: {
    btnColor: {
      type: String,
      default: 'bg-brand-colour-alt',
    },    
    btnTextColor: {
      type: String,
      default: 'text-white'
    }
  }
})
export default class ServiceQualificationLong extends Vue {
  @Prop({ default: null })
  readonly carrier: Carrier | null

  @Prop({ default: null })
  readonly hideTick: Boolean | false
  
  @Prop({ default: null })
  readonly showLink: Boolean | false

  @Prop({ default: true })
  readonly redirect: boolean

  @SQ.Getter
  sq

  @SQ.Getter
  address

  @SQ.Getter
  networks

  @SQ.Action
  serviceQualification

  @SQ.Getter
  primaryNetwork

  @SQ.Mutation
  setAdvancedSearch

  @SQ.Getter
  locationId
  
  @SQ.Getter
  sqId

  @SQ.Getter
  isBtr

  loading = false

  advancedSearch = false

  showRedirect = false

  hispRedirects = ['MERRYLANDS COURT']

  toggleAdvancedSearch() {
    this.advancedSearch = !this.advancedSearch
  }

  addressString() {
    return `
      ${this.address.address1},
      ${this.address.address2},
      ${this.address.suburb},
      ${this.address.state}
    `
  }

  async runSqCheck() {
    await this.setAdvancedSearch(false)
    this.loading = true
    
    this.$emit('sq-started')
    if ((process.env.brand as any).code === 'FUZ' || (process.env.brand as any).code === 'HIS') {
      
      this.showRedirect = true
    }
    await this.serviceQualification()

    if (((process.env.brand as any).code === 'FUZ' || (process.env.brand as any).code === 'HIS') && this.sqId) {
      const condition = this.sqId
      let seconds = 0

      const timer = setInterval(() => {
        if (condition || seconds > 3) {
            clearInterval(timer)
            window.location.href = `${mergeUrl}?sq=${this.sqId}`
        }
        seconds+=1;
        }, 1000);
    } else {
      
      this.loading = false

      this.$emit('sq-completed')
      // console.log(this.sq.address.developmentName.toUpperCase())

      // if (this.sq.address.developmentName && this.hispRedirects.includes(this.sq.address.developmentName.toUpperCase())) {
        
      //   console.log(this.sq.address.developmentName.toUpperCase())
      //   this.$router.push({
      //     path: '/coronation/customer'
      //   })
      // }

      if (this.redirect) {
        if (this.isBtr) {
          // redirect to BTR static page
          this.$router.push({
            path: '/build-to-rent/customer'
          })
        } else {
          this.$router.push({
            path: '/signup'
          })
        }
      }

    }
  }
}
